<template>
  <div id="cameraSetting" v-loading="loading" :element-loading-text="$t('alarm.a0')">
         <!-- 页面标题 -->
        <div class="header-title">
          <span>{{ $t('video.viddata024') }}</span>
        </div>
        <div class="header">
            <div class="header-left">
              <div class="header-condition">
                <span>{{$t('energy.enedata254') }}</span>
                <a-select v-model="siteId" style="width: 300px">
                  <a-select-option
                      v-for="(itme, index) in this.siteList"
                      :key="index"
                      :value="itme.no"
                      >
                      {{ itme.text }}
                    </a-select-option>
                </a-select>
              </div>
            </div>
            <div>
              <a-button type="primary" style=""  @click="initPage()"> {{ $t('energy.enedata006') }} </a-button>
              <a-button type="primary" style="margin-left:10px;"  @click="save()"> {{ $t('energy.enedata023') }} </a-button>
            </div>
        </div>
        <div class="main" ref="main">
              <a-table
                  :columns="columns" 
                  :data-source="cameraSettingList"
                  :row-key="record => record.cameraId"  
                  :pagination="pagination"  
                  :scroll="tscroll"
              >
               <template slot="name" slot-scope="text, record">
                  <div class="editable-row-operations">
                      <a-input v-model="record.name"  @change="e => handleValChange(text,e.target.value, record.cameraId,'name')"></a-input>
                  </div>
                </template>
              </a-table>
        </div>
  </div>
  
</template>
<script>
import { getCameraList,saveCameraList } from "../../api/video";
import { downloadUrl } from "../../utils/utils";
import moment from "moment";
export default {
  data(){
    return {
        cameraSettingList:[],
        cameraSetting:'',
        loading:false,
        tscroll:{x:1000,y:280},
        columns:[
            {title: this.$t('video.viddata006')		 , dataIndex: 'name'                            ,width:200 ,scopedSlots: { customRender: 'name' }        },                    
            // {title: this.$t('video.viddata004')		 , dataIndex: 'cameraType'                      ,width:150        },                    
            {title: this.$t('video.viddata007')		 , dataIndex: 'cameraIpAddress'                 ,width:100        },                    
            {title: this.$t('video.viddata008')		 , dataIndex: 'cameraHttpPort'                  ,width:100        },                    
            {title: this.$t('video.viddata009')		 , dataIndex: 'recorderName'                    ,width:150        },  
            {title: this.$t('video.viddata010')		 , dataIndex: 'recorderIpAddress'               ,width:100        },                    
            {title: this.$t('video.viddata011')		 , dataIndex: 'recorderVideoPort'               ,width:100        },                   
            // {title: this.$t('video.viddata014')		 , dataIndex: 'bandLimitingFlag' 				        ,width:150		    },	                 
            // {title: this.$t('video.viddata016')		 , dataIndex: 'omnidirectionalCorrection'       ,width:150        },   
        ],
        pagination:false,
        siteId:'',
        siteList:[],

    }
  },
  activated() {
    this.initPage();  
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
      initPage(){
          this.siteId = this.$route.query.id+'';
          this.loading = true;
          getCameraList( this.siteId,0)
            .then((res) => {
            console.log("res");
            console.log(res);
            this.cameraSettingList = res.data.videoCameras;
            this.siteList = res.data.siteList;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
      },
      save(){
          let params = new Array();
          for (let i = 0; i < this.cameraSettingList.length; i++) {
              const el = this.cameraSettingList[i];
              if(el.name!=''){
                  let para = {
                      bandLimit: 0,
                      id: el.cameraId,
                      name: el.name,
                      omnidirectionalCorrection: 0
                  }
                  params.push(para);
              }
          }
           console.log("CameraList params",params);
          let data = {
               action:          2,
              clientId:         0,
              sitegrId:         0,
              siteId:           this.siteId,
              tenantId:         0,
              videoCameraSetBeans:params
          }
          this.$confirm({
              // title: "确定要进行设定内容注册吗?",
              title:this.$t("energy.enedata184"),
              centered: true,
              onOk: () => {
                  this.loading = true;
                  saveCameraList(data)
                      .then((res) => {
                        console.log("saveCameraList res",res);
                        if(res.errorCode==='00' || res.errorCode==='01'){
                            this.$message.info(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                        this.loading = false;
                  }).catch((err) => {
                      console.log(err);
                      this.loading = false;
                  });
              }
          });
          
      },
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let width = this.$refs.main.clientWidth;
              let tableHeight = height - 80;
              this.tscroll.y = tableHeight;
              // this.tscroll.x = width;
              console.log(this.tscroll);
          }, 0);
      },
       handleValChange(ov,value, key,col){
          const newData = [...this.cameraSettingList];
          const target = newData.filter(item => key === item.cameraId)[0];
          const msg = this.vailString(this.$t('video.viddata006'),value,1,50);
          if(msg != ''){
              this.$message.error(msg);
              if (target) {
                  target[col] = ov;
              }
              return;
          }else{
              target.edit = true;
          }

      },
      vailString(name,value,min,max){
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;

          let code = '';
          let msg = '';
          if(''===value){
              msg = this.$t("energy.enedata307");
          } else if(value.length<min || value.length>max){
              msg = this.$t("energy.enedata308");
              msg = msg.replace("{text}",name);
              msg = msg.replace("{star}",min);
              msg = msg.replace("{end}",max);
              return msg;
          } else if(regEn.test(value)) {
              msg = this.$t("energy.enedata309");
          } else {
              return "";
          }
          if(msg != ''){
              //let msg = this.$t(code);
              msg = msg.replace("{text}",name);
              return msg;
          }else{
              return "";
          }
      },
  }
}
</script>
<style scoped>

#cameraSetting {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}
.header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    margin-right: 20px;
    background-color: #7682ce;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
}
.header-left {
    display: flex;
    justify-content: flex-start;
}
.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}
.main{
    width: 100%;
    height: calc(100% - 60px);
}
</style>